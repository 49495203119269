@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Montserrat;
  src: url("./assets/fonts/Montserrat.ttf") format("truetype");
}

body {
  font-family: Montserrat !important;
  background-color: #f5f5f5;
  color: #282827;
}
.thmeSelect div {
  height: 2.7rem !important;
}

.thmeSelect div span {
  display: flex !important;
  align-items: center !important;
}

.thmeSelect div span input {
  height: 2.7rem !important;
}

:where(.css-dev-only-do-not-override-bv33a).ant-select .ant-select-arrow {
  top: 68% !important;
}

.ant-dropdown-menu-item:hover {
  background-color: #fff2e8 !important;
}

.pd-zero .ant-modal-content {
  padding: 0 !important;
}

.thin-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.thin-scroll::-webkit-scrollbar {
  width: 6px;
  height: 4px;
  background-color: #f5f5f5;
}

.thin-scroll::-webkit-scrollbar-thumb {
  background-color: #000000;
  border: 2px solid #555555;
}

.ant-spin-dot-item {
  background-color: #fff !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
}

.ant-table-wrapper .ant-table-thead tr th {
  background-color: #fff !important;
  color: #fc8019 !important;
  text-align: center !important;
}

.ant-table-wrapper .ant-table-tbody tr td {
  border-radius: 5px !important;
  border: none !important;
  text-align: center !important;
}

.ant-table-wrapper .ant-table-tbody tr:nth-child(odd) {
  background-color: #fafafa !important;
}

.ant-table-row:hover td {
  background-color: #fff2e8 !important;
}

.ant-table-wrapper .ant-table-thead > tr {
  box-shadow: 0px 2px 12px rgb(0 0 0 / 10%) !important;
  border-radius: 10px !important;
}

.pos-container {
  height: 94vh;
}

.cart-container {
  height: 50vh;
}

.cart-action-container {
  height: 43vh;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.cart-btns{
  height: 3.5rem !important;
  font-size: 1.125rem;
}

.cart-btn-container{
  margin-top: 1rem;
}

.product-container{
  height: 80vh;
}


@media only screen and (max-width: 880px) {
 .cart-btns{
  font-size: 1rem;
 }
}


@media only screen and (max-height: 830px) {
  .cart-container {
    height: 48vh;
  }
  .cart-action-container {
    height: 45vh;
  }
  .product-container{
    height: 76vh;
  }
}

@media only screen and (max-height: 760px) {
  /* .pos-navbar {
    height: 7vh;
  } */
  .pos-container {
    height: 93vh;
  }
  .cart-container {
    height: 42vh;
  }
  .cart-action-container {
    height: 51vh;
  }
}

@media only screen and (max-height: 710px) {
  .cart-container {
    height: 40vh;
  }
  .cart-action-container {
    height: 53vh;
  }
  .product-container{
    height: 74vh;
  }
}

@media only screen and (max-height: 685px) {
  .cart-container {
    height: 39vh;
  }
  .cart-action-container {
    height: 52vh;
  }
}

@media only screen and (max-height: 650px) {
  .pos-container {
    height: 92vh;
  }
  .cart-container {
    height: 40vh;
  }
  .cart-action-container {
    height: 52vh;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .cart-btns{
    height: 2.5rem !important;
  }
  .cart-btn-container{
    margin-top: 0.5rem;
  }
  .product-container{
    height: 72vh;
  }
}

@media only screen and (max-height: 560px) {
  /* .pos-navbar {
    height: 9vh;
  } */
  .pos-container {
    height: 91vh;
  }
  .cart-container {
    height: 45.5vh;
  }
  .cart-action-container {
    height: 45.5vh;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .cart-btns{
    height: 2.5rem !important;
  }
  .cart-btn-container{
    margin-top: 0.5rem;
  }
}
